import { useState } from "react";

export const Timer = () => {
  const [days, setDays] = useState('');
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');

  (function () {
    const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24;

    //I'm adding this section so I don't have to keep updating this pen every year :-)
    //remove this if you don't need it
    let today = new Date(),
      dd = String(today.getDate()).padStart(2, "0"),
      mm = String(today.getMonth() + 1).padStart(2, "0"),
      yyyy = today.getFullYear(),
      nextYear = yyyy + 1,
      dayMonth = "06/22/",
      birthday = dayMonth + yyyy;

    today = mm + "/" + dd + "/" + yyyy;
    if (today > birthday) {
      birthday = dayMonth + nextYear;
    }
    //end

    const countDown = new Date(birthday).getTime(),
      x = setInterval(function () {

        const now = new Date().getTime(),
          distance = countDown - now;
        setDays(Math.floor(distance / (day)));
        setHours(Math.floor((distance % (day)) / (hour)));
        setMinutes(Math.floor((distance % (hour)) / (minute)));
        setSeconds(Math.floor((distance % (minute)) / second));

        //do something later when date is reached
        if (distance < 0) {
          document.getElementById("headline").innerText = "It's my birthday!";
          document.getElementById("countdown").style.display = "none";
          document.getElementById("content").style.display = "block";
          clearInterval(x);
        }
        //seconds
      }, 0)
  }());
  if (days < 0 && hours < 0 && minutes < 0 && seconds < 0) {
    return <div id="countdown">All the seconds, all the minutes, all the hours- they were worth it for this moment. I love you, always.</div>
  }
  return <div id="countdown">
    {days > 0 && <div>{days} days</div>}
    {hours > 0 && <div>{hours} hours</div>}
    {minutes > 0 && <div>{minutes} minutes</div>}
    {seconds > 0 && <div> and {seconds} seconds,</div>}
    to your tightest hug!
  </div>
}