import confetti from 'canvas-confetti';
import './styles.css'
import { useEffect, useState } from 'react'
import { collection, addDoc, getDocs } from "firebase/firestore";
import { db } from '../../firebase';
import numberToWord from "number-to-words"
import {
  TXT_NUDGE_INIT,
  TXT_NUDGE_SUCCESS,
  HEART_ICON_ARRAY,
} from '../../constants'
import { Timer } from '../Timer'
// import { Analytics } from '../Analytics'

const sendNudgeToDB = async () => {
  try {
    await addDoc(collection(db, "nudges"), {
      nudgedAt: `${new Date().toLocaleDateString()}`,
      timestamp: new Date().toString()
    });
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}
const sendCoupon = async () => {
  try {
    await addDoc(collection(db, "coupons"), {
      timestamp: new Date().toString()
    });
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

const getNudgesFromDB = async () => {
  let value;
  await getDocs(collection(db, "nudges"))
    .then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      value = newData;
    })
  localStorage.setItem('nudges', JSON.stringify(value));
  return value;

}

const sendNudgeToAlexa = () => {
  const requestPath = `https://api.voicemonkey.io/trigger?access_token=${process.env.REACT_APP_VOICEMONKEY_ACCESS_TOKEN}&secret_token=${process.env.REACT_APP_VOICEMONKEY_SECRET_TOKEN}&monkey=${process.env.REACT_APP_VOICEMONKEY_MONKEY}`;
  fetch(requestPath);
}

const getNudgesCountForToday = async () => {
  const nudgeData = await getNudgesFromDB();
  return nudgeData?.filter((item) => item.nudgedAt === new Date().toLocaleDateString()).length
}

const debounce = (func, timeout = 600) => {

  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export const MainButton = () => {
  const [buttonLabel, setButtonLabel] = useState(TXT_NUDGE_INIT);
  const [nudgeCount, setNudgeCount] = useState();
  const [fadeClass, setFadeClass] = useState('fade-in-out');
  const [nudgesInLast5Minutes, setNudgesInLast5Minutes] = useState(0);
  const [heartEmoji, setHeartEmoji] = useState();
  const [couponButtonLabel, setCouponButtonLabel] = useState(`gift a coupon`);
  // const [popoverVisible, setPopoverVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const count = await getNudgesCountForToday();
      setNudgeCount(count);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (nudgeCount > 0) {
      setFadeClass('fade-in-out');
    }
    setHeartEmoji(HEART_ICON_ARRAY[Math.floor(Math.random() * HEART_ICON_ARRAY.length)]);
  }, [nudgeCount]);

  useEffect(() => {
    const interval = setInterval(() => {
      setNudgesInLast5Minutes(0);
    }, 300000); // 300000 milliseconds = 5 minutes
    return () => clearInterval(interval);
  }, []);

  const onClick = () => {
    sendNudgeToDB();
    sendNudgeToAlexa();
    setFadeClass('');
    setNudgesInLast5Minutes(nudgesInLast5Minutes + 1);

    confetti({
      particleCount: 300,
      startVelocity: 40,
      spread: 100,
    });

    setButtonLabel(TXT_NUDGE_SUCCESS);
    setNudgeCount(nudgeCount + 1);
    setTimeout(() => {
      setButtonLabel(TXT_NUDGE_INIT);
    }, 4000);

  };


  return (
    <div className='container'>

      {/* {popoverVisible && (
        <div className='popover'>
          <div className='popover-header'>
            <div className='popover-title'>Analytics</div>
            <button className='link' onClick={() => setPopoverVisible(false)}>x</button>
          </div>
          <div className='popover-body'>
            <Analytics />
          </div>
        </div>
      )} */}


      {(
        <div className={`banner ${fadeClass}`} >
          {!!nudgeCount
            ? `you missed me ${numberToWord.toWords(nudgeCount)} times today ${heartEmoji}`
            : ''}
        </div>
      )
      }

      <button height="48px" className="miss-you-button" onClick={debounce(() => onClick())}>
        {buttonLabel}
      </button >

      <div className='banner-footer fade-in' style={{
        visibility: 'visible'
      }}>
        <Timer />
        <button
          className='coupon'
          onClick={() => {
            sendCoupon();
            setCouponButtonLabel('Coupon sent');
            setTimeout(() => {
              setCouponButtonLabel('Give me another coupon');
            }, 3000);
          }} >
          {couponButtonLabel}
        </button>

        {/* <div className='link' onClick={() => setPopoverVisible(true)}>
          analytics available now 🚀
        </div> */}

      </div>
    </div >
  );
}

